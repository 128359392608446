import React from 'react'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <DefaultLayout>
        <SEO title="404: Not Found" />
        <div className="flex justify-center">
          <div>
            <h1 className="text-lg">404: Page Not Found</h1>
            <p className="text-sm">お探しのページは見つかりませんでした。</p>
            <br />
            <img className="max-w-2xl" src="/ogp.png"></img>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default NotFoundPage
